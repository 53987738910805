
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo, DicList } from '../../types/common'
import { IssueDetail } from '../../types/inspection'
import { PatrolReportMonthStatistic } from '@/types/inspection'
import Echarts from 'echarts'

@Component
export default class MonthStatistical extends Vue {
  private searchInfo: { patrolType: string; PatrolMonth: string} = {
    patrolType: '',
    PatrolMonth: ''
  }

  private tableData: IssueDetail[]=[]
  private dicList: DicInfo[] = []
  private _chart: any = null
  private loading = false
  private PatrolReportMonthStatistic: any = {
    analysis: {},
    totality: {}
  }

  created (): void {
    this.searchInfo.PatrolMonth = this.$dayjs().format('YYYY-MM')
    this.getPatrolTypeList()
    this.getList()
    this.getReaminPro()
    window.addEventListener('resize', this.eChartResize)
  }

  beforeDestroy () {
    this._chart && this._chart.dispose()
    window.removeEventListener('resize', this.eChartResize)
  }

  eChartResize () {
    this._chart && this._chart.resize()
  }

  // 获取巡查类别列表
  getPatrolTypeList () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, { dicType: 'patrolType' }).then(res => {
      this.dicList = res.patrolType || []
    })
  }

  // 查询
  onSearch (): void {
    this.getList()
    this.getReaminPro()
  }

  // 获取月报数据
  getList (): void {
    this.loading = true
    const info = {
      patrolType: this.searchInfo.patrolType,
      month: this.searchInfo.PatrolMonth
    }
    this.$axios.get<PatrolReportMonthStatistic>(this.$apis.inspectionReport.selectMonthStatistic, info).then(res => {
      console.log(res)
      this.PatrolReportMonthStatistic = res || []
      this.initChart(res.totality, 'chart1')
      this.initChart(res.totality, 'chart2')
    }).finally(() => {
      this.loading = false
    })
  }

  initChart (data: any, ref: string) {
    let data1: Array<string> = []
    let data2: Array<string> = []
    let data3: Array<string> = []
    let colors: Array<string> = []
    if (ref === 'chart1') {
      data1 = data.currentDatas || []
      data2 = data.currentCompeletedDatas || []
      data3 = data.currentPerCompeletedDatas || []
      colors = ['#7D27FF', '#36CBCB', '#2FC25A']
    } else if (ref === 'chart2') {
      data1 = data.currentDatas || []
      data2 = data.beforeDatas || []
      data3 = data.perDatas || []
      colors = ['#FFC117', '#5B8FF9', '#36CBCB']
    }
    // const max1 = this.calMax([this.calMax(data1), this.calMax(data2)])
    // const min1 = this.calMin([this.calMin(data1), this.calMin(data2)])
    // const max2 = this.calMax(data3)
    // const min2 = this.calMin(data3)
    // console.log(max1, min1, max2, min2)
    const option = {
      tooltip: {
        trigger: 'axis',
        formatter (res: any) {
          return `${res[0].name}<br>
                  ${res[0].marker}${res[0].seriesName} ${res[0].value}<br>
                  ${res[1].marker}${res[1].seriesName} ${res[1].value}<br>
                  ${res[2].marker}${res[2].seriesName} ${res[2].value ? res[2].value + '%' : ''}`
        }
      },
      color: colors,
      grid: {
        top: 40,
        bottom: 30,
        right: 45,
        left: 40
      },
      legend: {
        show: true,
        itemWidth: 20,
        itemHeight: 1,
        left: 10
      },
      xAxis: [
        {
          type: 'category',
          data: data.projectTypeNames,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, .45)'
            }
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          // max: max1,
          splitNumber: 5,
          // interval: (max1 - min1) / 5,
          axisLabel: {
            formatter: '{value}',
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        },
        {
          type: 'value',
          min: 0,
          // max: max2,
          splitNumber: 5,
          // interval: (max2 - min2) / 5,
          axisLabel: {
            formatter: '{value}%',
            color: 'rgba(0, 0, 0, .45)',
            fontSize: 12
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: ref === 'chart1' ? '问题数' : '本月',
          type: 'bar',
          barWidth: 16,
          data: data1
        },
        {
          name: ref === 'chart1' ? '整改数' : '上月',
          type: 'bar',
          barWidth: 16,
          data: data2
        },
        {
          name: ref === 'chart1' ? '整改率' : '环比',
          type: 'line',
          smooth: true,
          yAxisIndex: 1,
          data: data3
        }
      ]
    }
    this.$nextTick(() => {
      this._chart = Echarts.init(this.$refs[ref] as any)
      this._chart.clear()
      this._chart.setOption(option)
    })
  }

  // 计算最大值
  calMax (arr: any) {
    return Math.ceil(Math.max(...arr) / 10) * 10
  }

  // 计算最小值
  calMin (arr: any) {
    return Math.floor(Math.min(...arr) / 10) * 10
  }

  // 问题分析小计
  getSummaries (params: any) {
    const issueAmount = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.issueAmount)
    }, 0)
    const issueAmountCompeleted = params.data.reduce((prev: any, next: any) => {
      return prev + parseInt(next.issueAmountCompeleted)
    }, 0)
    return ['小计', issueAmount, '', issueAmountCompeleted, '']
  }

  // 获取遗留问题
  getReaminPro (): void {
    this.loading = true
    const info = {
      patrolType: this.searchInfo.patrolType,
      month: this.searchInfo.PatrolMonth
    }
    this.$axios.get(this.$apis.inspectionReport.selectUnCompeletedIssuePage, info).then(res => {
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 获取问题图片
  getIssueFileUrl (files: any) {
    return files.map((item: any) => {
      return item.filePrefix + item.fileUrl
    })
  }
}
